import store from '@/store';
import router from '@/router';
import { ABILITY_ACTIONS, ABILITY_SUBJECTS } from '@/constants/abilityParams';

const AUTH_TOKEN = localStorage.getItem('jwt_access_token');

export class WebSocketService {
    constructor () {
        this.url = null;
        this.jwtToken = AUTH_TOKEN;
        this.socket = null;
    }

    setAuthToken (token) {
        this.jwtToken = token;
    }

    setBaseUrl (url) {
        this.url = url;
    }

    connect () {
        const fullUrl = `${this.url}&token=${this.jwtToken}`;
        this.socket = new WebSocket(fullUrl);

        this.socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            const ability = store.getters['authorization/ability'];

            switch (message.event) {
            case 'new_incident':
                if (router.currentRoute.name !== 'incidents' &&
                    ability.can(ABILITY_ACTIONS.READ, ABILITY_SUBJECTS.INCIDENT) &&
                    ability.can(ABILITY_ACTIONS.VIEW, ABILITY_SUBJECTS.INCIDENT_TAB)) {
                    store.commit('incidents/SET_NEW_INCIDENTS_NOTIFICATION', true);
                }
                break;
            default:
                break;
            }
        };
    }

    close () {
        if (this.socket) {
            this.socket.close();
        }
    }
}

export default new WebSocketService();
