import HttpService from '@/servises/HttpService';
import SecondaryHttpService from '@/servises/secondaryHttpService';
import commonApi from '@/utils/api/common';
import territoriesApi from '@/utils/api/territories';
import countriesApi from '@/utils/api/countries';
import KMSHttpService from '@/servises/kmsHttpService';
import WebSocketService from '@/servises/WebSocketService';

export default {
    namespaced: true,
    state: {
        host: '',
        agencyConfiguration: null,
        territories: [],
        totalTerritoriesAmount: null,
        countries: [],
        totalCountriesAmount: null,
        apiUrl: '',
        apiV2Url: '',
        apiReportsUrl: '',
        websocketUrl: '',
        websocketService: null
    },
    mutations: {
        SET_HOST (state, payload) {
            state.host = payload;
        },
        SET_AGENCY_CONFIGURATION (state, payload) {
            state.agencyConfiguration = payload;
        },
        SET_TERRITORIES (state, payload) {
            state.territories = payload;
        },
        SET_TOTAL_TERRITORIES_AMOUNT (state, payload) {
            state.totalTerritoriesAmount = payload;
        },
        SET_TOTAL_COUNTRIES_AMOUNT (state, payload) {
            state.totalCountriesAmount = payload;
        },
        SET_COUNTRIES (state, payload) {
            state.countries = payload;
        },
        SET_API_URL (state, payload) {
            state.apiUrl = `${payload}/api`;
        },
        SET_API_V2_URL (state, payload) {
            state.apiV2Url = `${payload}/kms-api`;
        },
        SET_API_REPORTS_URL (state, payload) {
            state.apiReportsUrl = `${payload}/reports-api`;
        },
        SET_WEBSOCKET_URL (state, payload) {
            state.websocketUrl = payload;
        },
        SET_WEBSOCKET (state, payload) {
            if (state.websocketService) {
                state.websocketService.socket.close();
            }
            state.websocketService = payload;
        }
    },
    actions: {
        setBaseApiUrl ({ state }) {
            KMSHttpService.setBaseUrl(state.apiV2Url);
            return HttpService.setBaseUrl(state.apiUrl);
        },
        setBaseBackendUrl ({ state }) {
            return SecondaryHttpService.setBaseUrl(state.apiReportsUrl);
        },
        setBaseWebSocketUrl ({ state }) {
            return WebSocketService.setBaseUrl(state.websocketUrl);
        },
        getAgencyConfiguration ({ state, commit }) {
            if (state.host === 'superadmin') {
                const data = {
                    name: 'superadmin',
                    language: 'en-EN',
                    display_name: 'Superadmin'
                };
                commit('SET_AGENCY_CONFIGURATION', data);
            } else {
                return commonApi.getAgencyConfiguration(state.host)
                    .then(data => {
                        commit('SET_AGENCY_CONFIGURATION', data.data[0]);
                    });
            }
        },
        getTerritories ({ commit }, additionalParams = null) {
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return territoriesApi.getTerritories(params)
                .then(({ data }) => {
                    commit('SET_TERRITORIES', data.data.map(el => {
                        return {
                            value: el.id,
                            text: el.attributes.name
                        };
                    }));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_TERRITORIES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getTerritoriesLazyLoading ({ commit, state }, additionalParams = null) {
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return territoriesApi.getTerritories(params)
                .then(({ data }) => {
                    const result = [...state.territories].concat(data.data.map(el => ({
                        value: el.id,
                        text: el.attributes.name
                    })));

                    commit('SET_TERRITORIES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_TERRITORIES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getCountries ({ commit, rootState }, additionalParams = null) {
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return countriesApi.getCountries(params)
                .then(({ data }) => {
                    commit('SET_COUNTRIES', data.data.map(el => {
                        return {
                            value: el.id,
                            text: rootState.locale.currentLocale === 'pl'
                                ? `${el.attributes.code} ${el.attributes.name_pl}`
                                : `${el.attributes.code} ${el.attributes.name_en}`
                        };
                    }));

                    if (data.meta.stats) {
                        commit('SET_TOTAL_COUNTRIES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        getCountriesLazyLoading ({ commit, state, rootState }, additionalParams = null) {
            const params = {
                sort: 'name',
                'stats[total]': 'count'
            };

            if (additionalParams) {
                Object.keys(additionalParams).forEach(k => {
                    params[k] = additionalParams[k];
                });
            }

            return countriesApi.getCountries(params)
                .then(({ data }) => {
                    const result = [...state.countries].concat(data.data.map(el => ({
                        value: el.id,
                        text: rootState.locale.currentLocale === 'pl'
                            ? `${el.attributes.code} ${el.attributes.name_pl}`
                            : `${el.attributes.code} ${el.attributes.name_en}`
                    })));

                    commit('SET_COUNTRIES', result);

                    if (data.meta.stats) {
                        commit('SET_TOTAL_COUNTRIES_AMOUNT', data.meta.stats.total.count);
                    }
                });
        },
        initializeWebSocket ({ commit, state }, token) {
            WebSocketService.setBaseUrl(state.websocketUrl);
            WebSocketService.setAuthToken(token);

            commit('SET_WEBSOCKET', WebSocketService);
            WebSocketService.connect();
        }
    }
};
